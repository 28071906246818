import React from 'react';
import { Link } from 'gatsby';
import {
  Root,
  Container,
  Item,
  BottleImg,
  Note,
  Desc,
  IconList,
  IconItem,
  ItemTitle,
  Actions,
  Button,
} from './Reserve.style';

import ReserveSrc from '../../images/reserve.jpg';
import LogoReserveSrc from '../../images/logo-reserve.svg';
import Icon1Src from '../../images/reserve-icon-1.svg';
import Icon2Src from '../../images/reserve-icon-2.svg';
import Icon3Src from '../../images/reserve-icon-3.svg';

const Reserve = () => {
  return (
    <Root>
      <Container>
        <Item className="--left">
          <BottleImg src={ReserveSrc} alt="" />
        </Item>
        <Item className="--right">
          <h2>
            <img src={LogoReserveSrc} alt="James Ownby Reserve Limited Logo" />
          </h2>
          <Note id="reserve_note" tabIndex={-1}>
            LIMITED RELEASE | ONLY AVAILABLE IN SOME MARKETS
          </Note>
          <Desc>
            Charcoal filtered using the Lincoln County Process and aged in
            virgin American charred oak barrels, James Ownby Reserve Tennessee
            Straight Bourbon Whiskey is a rich, warm, deep caramel, easy-sipping
            spirit with a distinctly southern soul.
            <br />
            94 Proof 47% ALC/ VOL
          </Desc>
          <IconList>
            <IconItem>
              <img src={Icon1Src} alt="" />
              <ItemTitle>CHARCOAL FILTERED</ItemTitle>
            </IconItem>
            <IconItem>
              <img src={Icon2Src} alt="" />
              <ItemTitle>OAK BARREL AGED</ItemTitle>
            </IconItem>
            <IconItem>
              <img src={Icon3Src} alt="" />
              <ItemTitle>EASY-SIPPING</ItemTitle>
            </IconItem>
          </IconList>
          <Actions>
            <Button
              className="--solid"
              as={Link}
              to="#locator"
              onClick={() => {
                setTimeout(() => {
                  const targetEl = document.getElementById('location-search');
                  if (targetEl) {
                    targetEl.focus();
                  }
                }, 100);
              }}
            >
              FIND NEARBY
            </Button>
            <Button
              className="--outline"
              as={Link}
              to="https://olesmoky.com/collections/james-ownby-reserve/products/james-ownby"
              target="_blank"
            >
              BUY ONLINE
            </Button>
          </Actions>
        </Item>
      </Container>
    </Root>
  );
};

export default Reserve;
