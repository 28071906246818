import styled from 'styled-components';

export const Root = styled.div`
  display: flex;
  justify-content: center;
  background-color: #eae1d0;

  @media (max-width: 1023px) {
    padding: 48px 0;
  }

  @media (min-width: 1024px) {
    height: 860px;
  }
`;

export const Container = styled.div`
  display: flex;
  width: 100%;
  max-width: 1114px;
  padding: 0 24px;

  @media (max-width: 1023px) {
    flex-direction: column;
    align-items: center;
  }
`;

export const Item = styled.div`
  @media (max-width: 459px) {
    width: 90%;
  }

  @media (min-width: 460px) {
    width: 85%;
  }

  @media (min-width: 640px) {
    width: 60%;
  }

  @media (min-width: 860px) {
    width: 50%;
  }

  &.--left {
    display: flex;
    align-items: flex-end;

    @media (min-width: 1024px) {
      padding-right: 16px;
    }
  }

  &.--right {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @media (max-width: 1023px) {
      margin-top: -32px;
    }

    @media (min-width: 1024px) {
      padding-left: 16px;
    }
  }
`;

export const BottleImg = styled.img`
  width: 100%;
  max-width: 520px;
`;

export const Note = styled.p`
  margin-top: 24px;
  margin-bottom: 16px;
  color: rgb(20, 31, 53);
  font-family: 'DharmaGothic';
  font-size: 24px;
  letter-spacing: 0.1em;
  line-height: 27.48px;
  text-align: center;
`;

export const Desc = styled.p`
  font-size: 18.42px;
  line-height: 30.69px;
  text-align: center;
`;

export const IconList = styled.ul`
  display: flex;
  align-items: center;
  margin-top: 16px;
`;

export const IconItem = styled.li`
  display: flex;
  flex-direction: column;
  align-items: center;

  &:not(:last-of-type) {
    @media (max-width: 523px) {
      margin-right: 15px;
    }

    @media (min-width: 524px) {
      margin-right: 40px;
    }
  }
`;

export const ItemTitle = styled.span`
  margin-top: 8px;
  color: #ce9b61;
  font-family: 'DharmaGothic';
  letter-spacing: 0.03em;
  line-height: 20px;

  @media (max-width: 523px) {
    font-size: 15px;
  }

  @media (min-width: 524px) {
    font-size: 18px;
  }
`;

export const Actions = styled.div`
  display: flex;
  margin-top: 44px;
`;

export const Button = styled.button`
  display: flex;
  align-items: center;
  height: 48px;
  padding: 0 12px;
  font-family: 'DharmaGothic';
  font-size: 30px;
  letter-spacing: 0.05em;

  &.--solid {
    color: #e9dfd0;
    background-color: #141f35;
  }

  &.--outline {
    color: #ce9b61;
    border: 1px solid #ce9b61;
  }

  &:not(:last-of-type) {
    margin-right: 14px;
  }
`;
